"use client";
import { reviews } from "@/constants/data";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import MaxWidthWrapper from "@/components/common/max-width-wrapper";
import { Card, CardContent } from "@/components/ui/card";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
const CustomerReviews = () => {
  return (<section className="w-full py-12 md:py-24 lg:py-32 bg-slate-50">
    <MaxWidthWrapper>
      <div className="flex flex-col items-center justify-center space-y-4 text-center">
        <div className="space-y-2">
          <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Customer Feedback</h2>
          <p className="max-w-[700px] text-gray-500 md:text-xl">
            Read what our customers have to say about our farm and mushrooms.
          </p>
        </div>
      </div>
      <Carousel opts={{
        align: "start"
      }} className="mt-12 w-full">
        <CarouselContent className="md:-ml-4 lg:-ml-8">
          {
            reviews.map((review) =>

              <CarouselItem key={review.name} className="md:basis-1/2 lg:basis-1/3 pb-10 md:pl-4 lg:pl-8">
                <Card className="h-full">
                  <CardContent className="flex flex-col h-[inherit] flex-shrink-0">
                    <blockquote className="text-left flex-1 text-sm font-medium leading-snug md:text-base lg:leading-normal">
                      {review.comment}
                    </blockquote>

                    <p className="mt-4 text-sm font-semibold text-right w-full">{review.name}</p>
                  </CardContent>
                </Card>
              </CarouselItem>
            )}
        </CarouselContent>
        <CarouselPrevious className="absolute left-4 top-1/2 -translate-y-1/2 rounded-full bg-white p-2 shadow-sm transition-colors hover:bg-gray-100">
          <ChevronLeftIcon className="h-5 w-5" />
        </CarouselPrevious>
        <CarouselNext className="absolute right-4 top-1/2 -translate-y-1/2 rounded-full bg-white p-2 shadow-sm transition-colors hover:bg-gray-100">
          <ChevronRightIcon className="h-5 w-5" />
        </CarouselNext>
      </Carousel>
    </MaxWidthWrapper>
  </section>)
};

export default CustomerReviews;
