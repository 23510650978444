import { PRODUCT_THUMBNAIL } from "./common";

export const products = [
  {
    slug: "oyster-mushroom",
    name: "Oyster Mushroom",
    thumbnail: PRODUCT_THUMBNAIL[0],
    unit:"200g",
    price:60
  },
  {
    name: "Button Mushroom",
    slug: "button-mushroom",
    unit:"200g",
    price:60,
    thumbnail: PRODUCT_THUMBNAIL[1]
  },
];


export const reviews = [
  {
    name: "Shahul Hameed",
    comment: `The technic and the methodology used for producing this organic type mushroom are good...
  Its good for health...`,
  },

  {
    name: "Murugan N",
    comment:
      "It's great organic mushroom farm near by Nanguneri , good and healthy mushroom in this place . K P mushroom are reasonable price and fresh, compare to other mushroom shops .",
  },

  {
    name: "Thanga Prabha",
    comment:
      "This Oyster mushroom was too tasty compared to other mushrooms and it has more health benefits ,got impressed bcz of the highly hygienic cultivation.Hatz off to the people who put lots of effort in making such an immune booster.",
  },
  {
    name: "Sumathy Lenin",
    comment:
      "I had a great experience.First time I have seen the cultivation of oyster mushroom in very hygienic condition.Maintainance was so good.We will keep on supporting you.All the best.Thank you.",
  },
];


