"use client"
import React, { useEffect, useState } from 'react'
import { Button } from '@/components/ui/button'
import { useCart } from '@/hooks/use-cart'
import type { Product } from '@/types/product'

const AddToCartButton = ({ product }: { product: Product }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const addItem = useCart((state) => state.addItem)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsSuccess(false)
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [isSuccess])

  return (
    <Button onClick={(event) => {
      event.nativeEvent.preventDefault()
      event.stopPropagation();
      addItem({ name: product.name, slug: product.slug, thumbnail: product.thumbnail, productId: product.productId, price: product.discountedPrice, unit: product.unit })
      setIsSuccess(true)
    }} size={"lg"} className='w-full'>
      {isSuccess ? "Added" : "Add to Cart"}
    </Button>
  )
}

export default AddToCartButton