"use client";
import Image from "next/image";
import Link from "next/link";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
export default function HomeCarousel({
  images,
}: {
  images: { lg: string; sm: string; link: string }[];
}) {

  return (<Carousel>
    <CarouselContent>
      {images.map((image, i) => {
        return (
          <CarouselItem key={i}>
            <Link
              href={image.link}
            >
              <picture className="relative w-full flex aspect-[0.75] sm:aspect-[2.56]">
                <source media="(max-width: 640px)" srcSet={image.sm} />
                {/* <Image
                  sizes=""
                  className="select-none"
                  src={image.lg}
                  alt={"Image"}
                  fill
                /> */}
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="h-full w-full bg-slate-50 animate-pulse rounded-lg"></div>
                </div>

                <img loading="eager" src={image.lg} alt={"Image"} className="absolute inset-0 w-full h-full object-cover object-top" />
              </picture>
            </Link>
          </CarouselItem>
        );
      })}
    </CarouselContent>
    <CarouselPrevious className="absolute left-4 top-1/2 opacity-25 hover:opacity-80 -translate-y-1/2 rounded-full bg-white p-2 shadow-sm transition-all">
      <ChevronLeftIcon className="h-5 w-5" />
    </CarouselPrevious>
    <CarouselNext className="absolute right-4 top-1/2 opacity-25 hover:opacity-80 transition-all -translate-y-1/2 rounded-full bg-white p-2 shadow-sm">
      <ChevronRightIcon className="h-5 w-5" />
    </CarouselNext>
  </Carousel>)

}
