"use client"
import { Card, CardContent } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { cn, formatPrice, shimmer, toBase64 } from "@/lib/utils";
import Image from "next/image";
import Link from "next/link";
import AddToCartButton from "../common/add-to-cart-button";
const ProductCard = ({ product }: { product: any }) => {
  if (!product) {
    return <div className="flex flex-col w-full">
      <div className="relative bg-zinc-100 aspect-video w-full overflow-hidden rounded-xl">
        <Skeleton className="w-full h-full" />
      </div>
      <Skeleton className="mt-4 w-2/3 h-4 rounded-lg" />
      <Skeleton className="mt-2 w-16 h-4 rounded-lg" />
    </div>
  }

  return (<>
    <Link className={cn(
      "invisible h-full w-full group/main", {
      "visible animate-in fade-in-5": !!product
    }
    )} href={`/products/${product.slug}`}>
      <Card>
        <CardContent>
          <div className="w-full group h-64 md:h-80 rounded-xl overflow-hidden relative">
            <img src={`data:image/svg+xml;base64,${toBase64(shimmer(700, 475))}`} alt="shimmer" className="absolute top-0 left-0 w-full h-full object-cover object-center duration-200" />
            <img loading="lazy" src={product.thumbnail.url} alt={product.name} sizes="80rem" className="absolute top-0 left-0 w-full h-full object-cover object-center group:hover:scale-101 duration-200" />
            {/* <Image
              src={product.thumbnail.url}
              alt={product.name}
              sizes="80rem"
              fill
              className="object-cover object-center hover:scale-101 duration-200"
              placeholder={`data:image/svg+xml;base64,${toBase64(
                shimmer(700, 475)
              )}`}
            /> */}
          </div>
          <div className="flex flex-col items-start space-y-6 md:space-y-0 md:items-end md:flex-row md:justify-between mt-6 px-1">
            <div className="flex flex-col flex-shrink-0 flex-grow space-y-2">
              <h3 className="font-semibold text-lg text-gray-800">{product.name}</h3>
              <p className="text-sm text-gray-500">{product.unit}</p>
              <div className="flex items-center gap-2">
                <p className="font-semibold text-lg">{formatPrice(product.discountedPrice)}</p>

                {
                  product.discount > 0 && <>
                  <p className={cn("font-medium text-base text-gray-500", {
                    "line-through": product.discount > 0
                  })}>{formatPrice(product.price)}</p>
                    <p className="bg-purple-500 text-white text-sm px-1 py-0.5 rounded">{`${product.discount} % Off`}</p>
                    {/* <p className="font-semibold">{formatPrice(product.price * ((100 - product.discount) / 100))}</p> */}
                  </>
                }
              </div>
            </div>
            <div className="w-full md:w-fit">
              <AddToCartButton product={product} />
            </div>
          </div>
        </CardContent>
      </Card>
    </Link>
  </>
  )
};

export default ProductCard;