import { cn } from '@/lib/utils'
import React from 'react'


type Props = {className?: string, children: React.ReactNode}
const MaxWidthWrapper = ({children, className} : Props) => {
  return (
    <div className={cn('max-w-screen-xl w-full px-6 md:px-20 mx-auto', className)}>
      {children}
    </div>
  )
}

export default MaxWidthWrapper