import { CartState } from '@/types/cart'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'


export const useCart = create<CartState>()(
  persist((set) => {
    return {
      items: [],
      addItem: (product) => set((state) => {
        // console.log(product)
        const index = state.items.findIndex((item) => item.product.productId == product.productId)
        const updatedItems = [...state.items]
        if (index !== -1) {
          updatedItems[index].quantity += 1
        } else {
          updatedItems.push({ product, quantity: 1 })
        }
        return { items: updatedItems }
      }),
      decreaseQuantity: (product) => set((state) => {
        // console.log(product)
        const index = state.items.findIndex((item) => item.product.productId == product.productId)
        
        if (index == -1) return state;
        const currentQuantity = state.items[index].quantity
        if (currentQuantity == 1) {
          return { items: state.items.filter((item) => item.product.productId != product.productId) }
        }
        const updatedItems = [...state.items]
        updatedItems[index].quantity -= 1  
        return { items: updatedItems }
      }),
      removeItem: (id) => set((state) => {
        // console.log(state, id)
        return { items: state.items.filter((item) => item.product.productId != id) }
      })
      , clearCart: () => set(() => ({ items: [] })),

    }
  }, {
    name: "cart-storage",
    storage: createJSONStorage(() => localStorage),
  })
)